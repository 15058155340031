import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
import { router } from '@/router';
import { team } from '@/http';
import { ref } from 'vue';
export default {
  __name: 'team',
  setup(__props) {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1);
    const _team = async () => {
      const {
        data
      } = await team({
        page: page.value,
        perPage: 10
      });
      loading.value = false;
      finished.value = data.current_page >= data.last_page;
      if (data.current_page === 1) {
        list.value = data.data;
      } else {
        list.value = list.value.concat(data.data);
      }
      if (!finished.value) {
        page.value++;
      }
    };
    _team();
    const back = () => {
      router.go(-1);
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        title: _ctx.$t('my_team'),
        placeholder: "",
        fixed: "",
        onClickLeft: back
      }, null, 8, ["title"]), _createVNode(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        finished: finished.value,
        "finished-text": _ctx.$t('no_more'),
        onLoad: _team
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "team-item"
          }, [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('account')), 1), _createElementVNode("span", null, _toDisplayString(item.email), 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('invitation_time')), 1), _createElementVNode("span", null, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('level')), 1), _createElementVNode("span", null, _toDisplayString(item.layers), 1)])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "finished-text"])], 64);
    };
  }
};